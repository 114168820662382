<script>

import Layout from "../../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Helper from '@/helpers/helper';

/**
 * Dashboard Component
 */
export default {
    page: {
    title: "Forêts",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Forêts",
      items: [
        {
          text: "Définition",
        },
        {
          text: "Forêts",
          active: true,
        },
      ],
      forests: [],
      loading: false,
      status: 201,
    };
  },
  methods: {
    loadList() {
      fetch(Helper.route('if/forests'), Helper.requestOptions())
      .then(response => {
          Helper.tokenChecker(response.status)
          return response.json()
      })
      .then(data => {
        console.log(data.data);
        this.forests = data.data
      })
    },
  },
  mounted() {
    this.loadList()
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Listes des Forêts de vos CTAF</h4>
            <p class="card-title-desc">
              Le tableau ci-dessous présente la listes de <code>Forêts de vos CTAF</code>.
            </p>

            <div class="table-responsive">
              <table class="table align-middle mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Forêt</th>
                    <th>Nom</th>
                    <th>Prénom</th>
                    <th>Sexe</th>
                    <th>Email</th>
                    <th>Téléphone</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(forest, i) in forests" :key="forest.id">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      <router-link :to="'/if/forests/'+forest.id">{{ forest.name }}</router-link>
                    </td>
                    <td>{{ forest.ctaf.lastname }}</td>
                    <td>{{ forest.ctaf.firstname }}</td>
                    <td>{{ forest.ctaf.sex }}</td>
                    <td>{{ forest.ctaf.email }}</td>
                    <td>{{ forest.ctaf.phone }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            
          </div>
        </div>
      </div>
    </div>


  </Layout>
</template>
